import React from "react";
import SiteConfig from "../../config/SiteConfig.json";
import { Link } from "gatsby";
import { Card, CardContent, Typography } from "@material-ui/core";
import { Row, Col } from 'reactstrap'
import _ from "lodash";
import Header from "../components/header";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import { CategoryIcons, CategoryOrder, popularTips } from "../../utils/Category-Icons";
import { isChrome, isSafari, isFirefox } from 'react-device-detect';

const sortCategories = arr => {
  let result = [];
  for (let i in CategoryOrder) {
    for (let j in arr) {
      if (arr[j].category === CategoryOrder[i]) {
        result.push(arr[j]);
      }
    }
  }
  return result;
};

const getPopularTips = (arr, lang) => {
  let result = [];
  for (let i in popularTips){
    for (let j in arr){
      for (let k in arr[j].posts){
        if(lang === "en"){
          if(popularTips[i] === arr[j].posts[k].meta && !(result.filter(post => post.meta === popularTips[i]).length > 0)){
            result.push(arr[j].posts[k])
          }
        }else{
          if(`/${lang}${popularTips[i]}` === arr[j].posts[k].meta && !(result.filter(post => post.meta === `/${lang}${popularTips[i]}`).length > 0)){
            result.push(arr[j].posts[k])
          }
        }
      }
    }
  }
  return result;
}

class TranslatedIndexPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      textIndent: '',
      textMargin: ''
    }
  }

  componentDidMount(){
    if(isChrome){
      this.setState({textIndent: '-1.35em', textMargin: '1.7em'})
    }else if(isFirefox){
      this.setState({textIndent: '', textMargin: '1.4em'})
    }else if(isSafari){
      this.setState({textIndent: '-1em', textMargin: '1.4em'})
    }
  }

  render() {
    let json = sortCategories(this.props.pageContext.json);
    let lang = this.props.pageContext.lang;
    let popularTopict = getPopularTips(json, lang)

    let { textIndent, textMargin } = this.state

    // json = _.sortBy(json, o => {
    //   if (lang === "en") {
    //     return o.category;
    //   } else {
    //     return o.translatedCategory;
    //   }
    // });

    return (
      <Layout>
        <Seo
          postLanguage={lang}
          postDescription={SiteConfig.seo.siteDescription}
        />
        <Header lang={lang} json={json} />

        <section
          style={{
            maxWidth: "1110px",
            margin: "auto",
            padding: "50px 20px 50px 20px"
          }}
        >
          <Row style={{justifyContent: 'center'}}>
            {json.map(jsonPost => {
              // brandPostsTitlesLength = 0;
              let categoryMeta;
              if (lang !== "en") {
                categoryMeta = `/${lang}/category/${_.toLower(jsonPost.category)
                  .split(" ")
                  .join("-")}`;
              } else {
                categoryMeta = `/category/${_.toLower(jsonPost.category)
                  .split(" ")
                  .join("-")}`;
              }
              return (
                <Col
                  xs="12"
                  sm="6"
                  md="4"
                  key={`${lang}-${jsonPost.category}`}
                  style={{ padding: "20px 40px", maxWidth: "400px", display: 'flex', justifyContent: 'center' }}
                >
                  <Link className="post-title" to={categoryMeta}>
                    <Card
                      style={{
                        // borderRadius: SiteConfig.content.homePage.card.borderRadius,
                        boxShadow: SiteConfig.content.homePage.card.boxShadow,
                        MozBoxShadow:
                          SiteConfig.content.homePage.card.boxShadow,
                        textAlign: "center",
                        maxWidth: '284px',
                        minWidth: '284px',
                      }}
                    >
                      <CardContent
                        style={{
                          paddingBottom: "16px",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          minHeight: '130px',
                          justifyContent: "center"
                        }}
                      >
                        {/* To Do - category icon */}
                        <div style={{ padding: "0 0 20px 0" }}>
                          <img
                            src={
                              CategoryIcons[jsonPost.category]
                                ? CategoryIcons[jsonPost.category]
                                : "/assets/General.svg"
                            }
                            alt="icon"
                            style={{ maxWidth: "25px", height: '25px' }}
                          />
                        </div>
                        <div style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
                          {`${
                            jsonPost.translatedCategory
                              ? _.replace(
                                  _.replace(
                                    jsonPost.translatedCategory,
                                    /@brand/g,
                                    SiteConfig.brand
                                  ),
                                  "Amp",
                                  "&"
                                )
                              : _.replace(
                                  _.replace(
                                    jsonPost.category,
                                    /@brand/g,
                                    SiteConfig.brand
                                  ),
                                  "Amp",
                                  "&"
                                )
                          }`}
                        </div>
                      </CardContent>
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </section>

        <section style={{ background: "#fff" }}>
          <div style={{ maxWidth: "1100px", margin: "auto", padding: "20px" }}>
            <Typography
              variant="h6"
              style={{ padding: "10px 22px", fontWeight: "bold" }}
            >
              Popular Tips
            </Typography>
            <Row style={{ paddingLeft: "22px" }}>
              {popularTopict.map((post, i) => {
                return (
                  <Col
                    xs='12'
                    sm='6'
                    md='4'
                    key={post.meta+i}
                    style={{ padding: "5px 11px" }}
                  >
                    <li 
                    style={{marginLeft: textMargin, textIndent: textIndent}}
                    >
                      <Link style={{ color: "#0A79CA" }} to={post.meta}>{
                        post.title
                      }</Link>
                    </li>
                  </Col>
                );
              })}
            </Row>
          </div>
        </section>

        <section
          style={{
            maxWidth: "1100px",
            margin: "auto",
            padding: "20px 20px 50px 20px"
          }}
        >
          <Typography
            variant="h6"
            style={{ padding: "10px 30px 30px 22px", fontWeight: "bold" }}
          >
            Additional Resources
          </Typography>
          <Row>
            <Col xs="12" sm="12" md="4" style={{ padding: "10px 30px" }}>
              <a
                href="mailto:support@bluemail.me"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Card
                  className="resources_c_wrapper"
                >
                  <img
                    src="/assets/ContactSupport.svg"
                    alt="ContactSupport"
                    style={{ width: "28px", marginLeft: '14px' }}
                  />
                  <CardContent style={{ paddingBottom: "16px" }}>
                    Contact Support
                  </CardContent>
                </Card>
              </a>
            </Col>
            <Col xs="12" sm="12" md="4" style={{ padding: "10px 30px" }}>
              <a href="mailto:sales@blix.net" style={{ color: "#000", textDecoration: "none" }}>
              <Card
                className="resources_c_wrapper"
              >
                <img
                  src="/assets/ContactCorporate.svg"
                  alt="ContactCorporate"
                  style={{ width: "28px", marginLeft: '14px' }}
                />
                <CardContent style={{ paddingBottom: "16px" }}>
                  Contact Corporate
                </CardContent>
              </Card>
              </a>
              
            </Col>
            {/* <Grid item xs={12} sm={6} md={4} style={{ padding: "10px 30px" }}>
              <Link
                to="/device-tips/"
                style={{ color: "#000", textDecoration: "none" }}
              >
                <Card
                  className="resources_c_wrapper"
                >
                  <img
                    src="/assets/DeviceTips.svg"
                    alt="DeviceTips"
                    style={{ width: "28px", marginLeft: '14px' }}
                  />
                  <CardContent style={{ paddingBottom: "16px" }}>
                    Device Tips
                  </CardContent>
                </Card>
              </Link>
            </Grid> */}
          </Row>
        </section>
      </Layout>
    );
  }
}

export default TranslatedIndexPage;
